import React, {useCallback, useEffect, useState} from "react";
import { Typography, Box, TextField, Button, InputAdornment, IconButton, Checkbox, Theme, FormControlLabel } from '@material-ui/core';
import {Link, Redirect, RouteComponentProps, withRouter} from "react-router-dom";
import { Dispatch, bindActionCreators } from "redux";
import { connect } from "react-redux";
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Visibility, VisibilityOff } from "@material-ui/icons";
import * as actions from "../signUpActions";
import {serverSelector, validationSelector} from "../../../shared/errors/errorSelectors";
import {invitedUserSelector, loadingSelector, whiteLabelSelector} from "../../../shared/sharedSelectors";
import { ApplicationState } from "../../../ApplicationState";
import {ErrorType, ValidationError} from "../../../shared/errors/ErrorModels";
import * as validation from '../../../shared/validation/validationHelper';
import Icon from "../../../shared/components/Icon";
import GoodIcon from '../../../svg/signup-1.svg';
import OkIcon from '../../../svg/signup-2.svg';
import BadIcon from '../../../svg/signup-3.svg';
import Ellipses from '../../../svg/title-ellipses.svg';
import {
    InvitedUserProfileModel,
    ReCaptchaModel,
    UserProfileModel,
    WhiteLabelProfile
} from "../../../shared/SharedModels";
import Loading from "../../../shared/components/Loading";
import {LoadingStateMap} from "../../../shared/loading/LoadingStateModel";
import * as sharedSelectors from "../../../shared/sharedSelectors";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {setValidationErrors} from "../../../shared/errors/errorActions";
import * as sharedActions from "../../../shared/sharedActions";

type Props = {
    userProfile: UserProfileModel | undefined;
    validationErrors: ValidationError[];
    invitedUser?: InvitedUserProfileModel;
    whiteLabel?: WhiteLabelProfile;
    signup: typeof actions.signUp;
    getInvitedUserProfile: typeof sharedActions.getInvitedUserProfile;
    signupInvitedUser: typeof actions.signupInvitedUser;
    confirmEmailVerification: typeof actions.confirmEmailVerification;
    loading: LoadingStateMap;
    serverErrors: ErrorType | null;
    verifyReCaptcha: typeof sharedActions.verifyReCaptcha;
    recaptchaResponse?: ReCaptchaModel;
    getSubscriptionTrialDays: typeof sharedActions.getSubscriptionTrialDays;
    subscriptionTrialDays: number;
};

const useStyles = makeStyles(() => createStyles({
    btn: {
        backgroundColor: "#F43B3B",
        color: "#fff",
    },
    agreement: {
        fontSize: "0.8rem",
    }
}));


// parameter c means client
const SignUpPage: React.FC<Props & RouteComponentProps<{ userid: string, signupStatus: string, c: string }>> = (props) => {
    const {executeRecaptcha} = useGoogleReCaptcha();
    const classes = useStyles();
    const { userid } = props.match.params;

    const { validationErrors, invitedUser, signup , serverErrors} = props;
    const [ redirect, setRedirect ] = useState("");
    const [ signupModel, setSignupModel ] = useState({
        userName: '',
        organizationName: '',
        email: '',
        password: '',
        password2: '',
        isTermsApproved: false,
        isTermsApproved2: false,
        showPassword: false,
        couponCode: ''
    });

    let policyLinks = {
        'termsService': 'https://ratemyservice.io/tos',
        'privacyPolicy': 'https://ratemyservice.io/privacy',
        'cookiePolicy': '' // Cookie policy is combined with the privacy policy
    };
    if (props.whiteLabel) {
        if (props.whiteLabel.termsService) policyLinks.termsService = props.whiteLabel.termsService;
        if (props.whiteLabel.privacyPolicy) policyLinks.privacyPolicy = props.whiteLabel.privacyPolicy;
        if (props.whiteLabel.cookiePolicy) policyLinks.cookiePolicy = props.whiteLabel.cookiePolicy;
    }


    useEffect(() => {
        if (props.userProfile) {
            const {organization} = props.userProfile;
            setRedirect(`/org${organization.id}/setup/customize`);
        }
    }, [props.userProfile]);

    useEffect(() => {
        props.getSubscriptionTrialDays();
    }, [])

    const handleReCaptchaVerify = useCallback(async (event) => {
        event.preventDefault();
        if (!executeRecaptcha) {
            setValidationErrors([{name: 'recaptcha', message: 'reCaptcha not yet available'}]);
            return;
        }

        const token = await executeRecaptcha('/signup');
        if (token) {
            props.verifyReCaptcha(token);
        }

    }, [executeRecaptcha]);

    useEffect(() => {
        if (props.recaptchaResponse) {
            if (!props.recaptchaResponse.success) {
                setValidationErrors([{name: 'recaptcha', message: 'reCaptcha was not successful'}]);
                return;
            }
            if (props.recaptchaResponse.action !== '/signup' || props.recaptchaResponse.score < 0.6) {
                setValidationErrors([{name: 'recaptcha', message: 'reCaptcha did not pass'}]);
                return;
            }

            submit();
        }
    }, [props.recaptchaResponse])

    useEffect(() => {
        if(userid && !invitedUser && !serverErrors) {
            const userId = Number(userid.replace("id", ""));
            props.getInvitedUserProfile(userId);
        }
    }, []);

    useEffect(() => {
        if(userid && invitedUser) setSignupModel({
            userName: invitedUser.fullName,
            organizationName: invitedUser.organizationName,
            email: invitedUser.login,
            password: '',
            password2: '',
            isTermsApproved: false,
            isTermsApproved2: false,
            showPassword: false,
            couponCode: ''
        });
    }, [userid, invitedUser]);

    const handleChange = (event: React.ChangeEvent<{ name?: string; value: any }>) => {

        if (!event.target.name) {
            return;
        }

        if (event.target.name === "isTermsApproved") {
            setSignupModel(prev => {
                return {
                    ...prev,
                    isTermsApproved: !prev.isTermsApproved,
                }
            });

            return;
        }

        if (event.target.name === "isTermsApproved2") {
            setSignupModel(prev => {
                return {
                    ...prev,
                    isTermsApproved2: !prev.isTermsApproved2,
                }
            });

            return;
        }

        setSignupModel(prev => {
            return {
                ...prev,
                [event.target.name!]: event.target.value,
            }
        });
    };

    // need to type
    const handleMouseDownPassword = (event: any) => {
        event.preventDefault();
    };

    const handleKeyPressPassword = (event: React.KeyboardEvent) => {
        if (event.key === 'Tab') {
            // event.preventDefault();
        }
    };

    const handleClickShowPassword = () => {

        setSignupModel(prev => {
            return {
                ...prev,
                showPassword: !prev.showPassword,
            }
        });
    };

    const submit = () => {
        if(userid && invitedUser)
        {
            props.signupInvitedUser({
                userId: Number(userid.replace("id", "")),
                fullName: signupModel.userName,
                password: signupModel.password,
                passwordConfirmation: signupModel.password2,
                isTermsApproved: signupModel.isTermsApproved,
            });
            return;
        }

        signup(signupModel);
    }

    const handleSignUpEnterPress = (e: React.KeyboardEvent<HTMLFormElement>) => {
        if(e.key == "Enter") {
            handleReCaptchaVerify(e);
        }
    };
    return (
        <Box className="entry-container-wrapper">
            {redirect && <Redirect to={redirect}/>}
            <Box className="banner-section">
                <Box className="info-container">
                    <Box className="trial-info">
                        {!window.location.href.includes("/signup/appsumo") && !window.location.href.includes("/UserInvite/") &&
                            <>
                                <Typography variant="h2">Try {props.subscriptionTrialDays > 0 ? `${props.subscriptionTrialDays}-day` : null} free trial</Typography>,
                                <Typography>No Credit Card Required</Typography>
                            </>
                        }
                    </Box>
                    <Box className="title-info">
                        <Typography variant="h6">Measure Customer Satisfaction in Realtime</Typography>
                        <Typography variant="h6">Reduce Churn rate and improve retention</Typography>
                        <Typography variant="h6">Identify unhappy customers pro-actively</Typography>
                    </Box>
                    <Box className="trial-info">
                        <Typography>This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of Service apply</Typography>
                    </Box>
                    <Box className="ellipses">
                        <Icon src={Ellipses} />
                    </Box>
                    <Box className="icons">
                        <Box><Icon src={GoodIcon} /></Box>
                        <Box><Icon src={OkIcon} /></Box>
                        <Box><Icon src={BadIcon} /></Box>
                    </Box>
                </Box>
            </Box>
            {props.loading.default && props.loading.default.isLoading ?
                <Box className='entry-container'>
                    <Loading />
                    <Typography>Saving your details, you will get an email to verify your account.</Typography>
                </Box>:
                <Box className='entry-container'>
                    <Box className="entry-option">
                        {!window.location.href.includes("/signup/appsumo") && !window.location.href.includes("/UserInvite/")  &&
                            [
                                <Typography className="entry-option-title" variant="h6" color='primary'>
                                    { userid && invitedUser ? `Join ${invitedUser.organizationName}` : "Create a new account & try for free" }
                                </Typography>
                            ]
                        }
                        <Box className='entry-box' justifyContent='space-around'>
                            <Box className='entry-box-item'>
                                <Box className="title" style={{minHeight: "auto"}}>
                                    <Typography variant='h6'><strong>Sign Up with Email</strong></Typography>
                                </Box>
                                <Box className='sign-box'>
                                    <form onSubmit={handleReCaptchaVerify} onKeyPress={handleSignUpEnterPress}>
                                        <TextField className='sign-box-item sign-box-input' name="userName" label="Full Name"
                                                   variant="outlined"
                                                   value={signupModel.userName}
                                                   error={validation.hasError(validationErrors, 'FullName')}
                                                   helperText={validation.getErrorText(validationErrors, 'FullName')}
                                                   onChange={handleChange}
                                        />
                                        <TextField className='sign-box-item' name="organizationName" label="Company Name" variant="outlined"
                                                   value={signupModel.organizationName}
                                                   error={validation.hasError(validationErrors, 'OrganizationName')}
                                                   helperText={validation.getErrorText(validationErrors, 'OrganizationName')}
                                                   disabled={ !!(userid && invitedUser) }
                                                   onChange={handleChange}
                                        />
                                        <TextField className='sign-box-item' name="email" label="Email" variant="outlined"
                                                   value={signupModel.email}
                                                   error={validation.hasError(validationErrors, 'Email')}
                                                   helperText={validation.getErrorText(validationErrors, 'Email')}
                                                   disabled={ !!(userid && invitedUser) }
                                                   onChange={handleChange}
                                        />
                                        <TextField
                                            className='sign-box-item'
                                            label="Password"
                                            type={signupModel.showPassword ? 'text' : 'password'}
                                            name='password'
                                            value={signupModel.password}
                                            variant="outlined"
                                            InputProps ={{
                                                endAdornment:
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            tabIndex="-1"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            onKeyDown={handleKeyPressPassword}
                                                        >
                                                            {signupModel.showPassword ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                            }}
                                            error={validation.hasError(validationErrors, 'Password')}
                                            helperText={validation.getErrorText(validationErrors, 'Password')}
                                            onChange={handleChange}
                                        />
                                        <TextField
                                            className='sign-box-item'
                                            label="Repeat Password"
                                            type={signupModel.showPassword ? 'text' : 'password'}
                                            name='password2'
                                            value={signupModel.password2}
                                            variant="outlined"
                                            InputProps={{
                                                endAdornment:
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            tabIndex="-1"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                        >
                                                            {signupModel.showPassword ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                            }}
                                            error={validation.hasError(validationErrors, 'PasswordConfirmation')}
                                            helperText={validation.getErrorText(validationErrors, 'PasswordConfirmation')}
                                            onChange={handleChange}
                                        />
                                        <TextField className='sign-box-item' name="couponCode" label="Coupon Code (Optional)" variant="outlined"
                                                   value={signupModel.couponCode}
                                                   error={validation.hasError(validationErrors, 'CouponCode')}
                                                   helperText={validation.getErrorText(validationErrors, 'CouponCode')}
                                                   disabled={ !!(userid && invitedUser) }
                                                   onChange={handleChange}
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={signupModel.isTermsApproved}
                                                    name="isTermsApproved"
                                                    color="primary"
                                                    required={true}
                                                    onChange={handleChange}
                                                />
                                            }
                                            label={
                                                <Box className={classes.agreement}>
                                                    <span>I agree to the </span>
                                                    <a href={policyLinks.termsService} target="_blank" >Terms of Service</a>
                                                    <span>{policyLinks.cookiePolicy ? ',' : ' and '}</span>
                                                    <a href={policyLinks.privacyPolicy} target="_blank" >Privacy Policy</a>
                                                    {policyLinks.cookiePolicy &&
                                                    <>
                                                        <span> and </span>
                                                        <a href={policyLinks.cookiePolicy} target="_blank" >Cookie Policy</a>
                                                    </>}
                                                </Box>
                                            }
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={signupModel.isTermsApproved2}
                                                    name="isTermsApproved2"
                                                    onChange={handleChange}
                                                />
                                            }
                                            className="terms-approved-checkbox"
                                            label={
                                                <Typography>I agree to the Terms of Service and Privacy Policy</Typography>
                                            }
                                        />
                                        <Button className={`${classes.btn} sign-box-btn`} type="submit" variant="contained">
                                            {!window.location.href.includes("/signup/appsumo") && !window.location.href.includes("/UserInvite/")
                                                ? "START YOUR FREE TRIAL NOW"
                                                : "CREATE ACCOUNT"
                                            }
                                        </Button>
                                    </form>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Typography style={{ display: "inline" }}>Already have one? </Typography><Link to="/signin">Sign in here</Link>
                </Box>
            }
        </Box>
    )

}

const mapStateToProps = (state: ApplicationState) => {
    return {
        userProfile: sharedSelectors.profileSelector(state),
        validationErrors: validationSelector(state),
        invitedUser: invitedUserSelector(state),
        whiteLabel: whiteLabelSelector(state),
        loading: loadingSelector(state),
        serverErrors: serverSelector(state),
        recaptchaResponse: state.globals.reCaptchaResponse,
        subscriptionTrialDays: state.globals.subscriptionTrialDays,
    }
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        signup: actions.signUp,
        signupInvitedUser: actions.signupInvitedUser,
        getInvitedUserProfile: sharedActions.getInvitedUserProfile,
        confirmEmailVerification: actions.confirmEmailVerification,
        verifyReCaptcha: sharedActions.verifyReCaptcha,
        getSubscriptionTrialDays: sharedActions.getSubscriptionTrialDays,
    }, dispatch);
};

const SignUp = connect(mapStateToProps, mapDispatchToProps)(SignUpPage);

export default withRouter(SignUp);
