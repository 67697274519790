import { fork, call } from 'redux-saga/effects';
import accountSaga from './AccountPage/accountSaga';

import { signInSaga } from './EntryPages/SignInPage/signInSaga';
import sharedSaga from './shared/sharedSaga';
import setupSaga from './SetupPage/setupSaga';
import lookupSaga from './lookups/lookupSaga';

import signUpSaga from './EntryPages/SignUpPage/signUpSaga';
import feedbackSaga from "./Feedback/feedbackSaga";
import reviewsSaga from "./ReviewsPage/reviewsSaga";
import settingsSaga from './SettingsPage/settingsSaga';
import resetPasswordSaga from "./EntryPages/ResetPasswordPage/resetPasswordSaga";
// import authSaga from './auth/redux/saga';

export function* rootSaga() {
    yield fork(signInSaga);
    yield fork(sharedSaga);
    yield fork(accountSaga);
    yield fork(setupSaga);
    yield fork(lookupSaga);
    yield fork(signUpSaga);
    yield fork(feedbackSaga);
    yield fork(reviewsSaga);
    yield fork(settingsSaga);
    yield fork(resetPasswordSaga);

    // yield fork(authSaga);
}