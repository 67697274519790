import React, {useCallback, useEffect, useState} from "react";
import { Typography, Box, TextField, Button, InputAdornment, IconButton, Theme } from '@material-ui/core';
import { Dispatch, bindActionCreators } from "redux";
import { connect } from "react-redux";
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {Redirect, RouteComponentProps, withRouter} from 'react-router-dom';
import Loading from "../../shared/components/Loading";
import '../entryPages.scss';
import * as signInActions from "./signInActions";
import { Visibility, VisibilityOff } from "@material-ui/icons";

import * as errorSelectors from '../../shared/errors/errorSelectors';
import { ApplicationState } from "../../ApplicationState";
import { ValidationError } from "../../shared/errors/ErrorModels";
import * as validation from '../../shared/validation/validationHelper';
import { Link } from "react-router-dom";
import {loadingSelector} from "../../shared/sharedSelectors";
import {LoadingStateMap} from "../../shared/loading/LoadingStateModel";
import {RouterModel} from "../../shared/router/RouterModel";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {setValidationErrors} from "../../shared/errors/errorActions";
import * as sharedActions from "../../shared/sharedActions";
import {ReCaptchaModel} from "../../shared/SharedModels";

type Props = {
    loading: LoadingStateMap;
    router: RouterModel;
    signIn: typeof signInActions.signIn;
    validationErrors: ValidationError[] | [];
    verifyReCaptcha: typeof sharedActions.verifyReCaptcha;
    recaptchaResponse?: ReCaptchaModel;
};

const useStyles = makeStyles((theme: Theme) => createStyles({
    btn: {
        backgroundColor: "#F43B3B",
        color: "#fff",
        marginTop: "10px",
    },
    agreement: {
        fontSize: "0.8rem",
    }
}));

const SignInPage: React.FC<RouteComponentProps & Props> = (props) => {
    const {executeRecaptcha} = useGoogleReCaptcha();
    const classes = useStyles();
    const { validationErrors, location, signIn } = props;

    const [ credentials, setCredentials ] = useState({
        email: '',
        password: '',
        showPassword: false,
    });

    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
            setValidationErrors([{name: 'recaptcha', message: 'reCaptcha not yet available'}]);
            return;
        }

        const token = await executeRecaptcha('/signin');
        if (token) {
            props.verifyReCaptcha(token);
        }

    }, [executeRecaptcha]);

    useEffect(() => {
        if (props.recaptchaResponse) {
            if (!props.recaptchaResponse.success) {
                setValidationErrors([{name: 'recaptcha', message: 'reCaptcha was not successful'}]);
                return;
            }
            if (props.recaptchaResponse.action !== '/signin') {
                setValidationErrors([{name: 'recaptcha', message: 'reCaptcha did not pass'}]);
                return;
            }
            const returnUrl = (location.search.match(/returnUrl=([^?]+)/) || [])[1];
            const options = returnUrl ? {returnUrl} : {};
            signIn({
                credentials,
                options,
            });
        }
    }, [props.recaptchaResponse])

    const handleChange = (event: React.ChangeEvent<{ name?: string; value: any }>) => {
        if (!event.target.name) {
            return;
        }

        setCredentials(prevCreds => {
            return {
                ...prevCreds,
                [event.target.name!]: event.target.value,
            }
        });
    }

    const handleClickShowPassword = () => {
        setCredentials(prevCreds => {
            return {
                ...prevCreds,
                showPassword: !prevCreds.showPassword,
            }
        });
    };

    // need to type
    const handleMouseDownPassword = (event: any) => {
        event.preventDefault();
    };

    const handleSignIn = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        handleReCaptchaVerify();
    }

    const handleSignInEnterPress = (e: React.KeyboardEvent<HTMLFormElement>) => {
        if(e.key == "Enter") handleReCaptchaVerify();
    };

    return (
        <Box className='login-container-wrapper'>
            <Box className='login-container'>
                <Box className='login-box' justifyContent='space-around'>
                    {props.router.redirectTo && <Redirect to={props.router.redirectTo} />}
                    <Box className='login-box-item'>
                        <Box className='sign-box'>
                            {
                                props.loading.default && props.loading.default.isLoading ? <Loading message={"Signing you in"} /> :
                                    <>
                                        <Typography className="title" variant='h6'><strong>Login to your account</strong></Typography>

                                        <form onKeyPress={handleSignInEnterPress} onSubmit={handleSignIn}>
                                            <TextField className='sign-box-item' name='email' value={credentials.email} label="Email" variant="outlined"
                                                       error={validation.hasError(validationErrors, 'Email')}
                                                       helperText={validation.getErrorText(validationErrors, 'Email')}
                                                       onChange={handleChange} />
                                            <TextField
                                                className='sign-box-item'
                                                label="Password"
                                                type={credentials.showPassword ? 'text' : 'password'}
                                                name='password'
                                                value={credentials.password}
                                                variant="outlined"
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                        >
                                                            {credentials.showPassword ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }}
                                                error={validation.hasError(validationErrors, 'Password')}
                                                helperText={validation.getErrorText(validationErrors, 'Password')}
                                                onChange={handleChange}
                                            />
                                            <Link to="/forgotpassword">Forgot your password?</Link>
                                            <Button
                                                type="submit"
                                                className={`${classes.btn} sign-box-btn`}
                                                variant="contained"
                                            >
                                                LOGIN
                                            </Button>

                                        </form>

                                        <Box>
                                            <Typography style={{ display: "inline" }}>Don't have an account? </Typography><Link to="/signup">Sign Up</Link>
                                        </Box>
                                    </>
                            }
                        </Box>
                    </Box>

                </Box>
            </Box>
        </Box>
    )

}

const mapStateToProps = (state: ApplicationState) => {
    return {
        loading: loadingSelector(state),
        router: state.router,
        validationErrors: errorSelectors.validationSelector(state),
        recaptchaResponse: state.globals.reCaptchaResponse
    }
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        signIn: signInActions.signIn,
        verifyReCaptcha: sharedActions.verifyReCaptcha,
    }, dispatch);
};

const SignIn = connect(mapStateToProps, mapDispatchToProps)(SignInPage);

export default withRouter(SignIn);
