import { takeLatest, take, put, call, select } from "redux-saga/effects";
import * as actions from './signUpActions';
import * as routerActions from '../../shared/router/routerActions';
import * as authActions from '../../auth/redux/actions';
import * as errorActions from '../../shared/errors/errorActions';
import * as loadingActions from '../../shared/loading/loadingStateActions';
import * as mappers from "./signUpMappers";
import * as authApi from '../../api/authenticationApi';
import {
    ConfirmVerificationResponse,
    SignupResponse,
    UserSignUpData,
    Tina4SignInTokenResult, AccessTokenResult,
} from "./signUpData";
import { OrganizationModel } from "../../shared/SharedModels";
import * as sharedActions from "../../shared/sharedActions";
import { ReenterSignupEmailModel, SignupInvitedUserModel, UserSignUpModel } from "./signUpModels";
import { alertMapper, customErrorMapper } from "../../shared/errors/ErrorMappers";
import { CustomError, ValidationError } from "../../shared/errors/ErrorModels";
import { handleErrorRequest } from "../SignInPage/signInSaga";
import { ActionType, getType } from "typesafe-actions";
import { organizationSelector } from "../../shared/sharedSelectors";
import { BadRequestError } from "../../auth/ApiError";
import {logout} from "../../auth/authAPI";
import {appSettings} from "../../config/appSettings";

const apiName = appSettings.apisMetadata.find(x => x.id === 'rms')!.name;

export default function* signUpSaga() {
    // implement via getType
    yield takeLatest(getType(actions.signUp), signUp);
    yield takeLatest(getType(actions.validateSignUpModel), validateSignUpModel);
    yield takeLatest(getType(actions.validateInvitedUserSignUpModel), validateInvitedUserSignUpModel);
    yield takeLatest(getType(actions.signupInvitedUser), signupInvitedUser);
    yield takeLatest(getType(actions.confirmEmailVerification), confirmEmailVerification);
    yield takeLatest(getType(actions.resendOTP), resendOTP);
    yield takeLatest(getType(actions.reenterSignupEmail), reenterSignupEmail);
}

function* signUp({ payload }: ReturnType<typeof actions.signUp>) {
    yield call(logout, apiName);
    yield put(loadingActions.begin());
    const model: UserSignUpModel = payload;
    const url = 'sign-up-token'

    yield put(actions.validateSignUpModel({ ...model }));

    const isValid: ActionType<typeof actions.validateSignUpModelCompleted> = yield take(getType(actions.validateSignUpModelCompleted));
    if (!isValid.payload) {
        yield put(loadingActions.reset());
        return;
    }

    try {
        const data: UserSignUpData = {
            fullName: model.userName,
            organizationName: model.organizationName,
            email: model.email,
            password: model.password,
            passwordConfirmation: model.password2,
            isTermsApproved: model.isTermsApproved,
            couponCode: model.couponCode
        };

        const authResponseData: Response = yield call(() => authApi.initialAuthToken(url));

        const authResponseBody: Tina4SignInTokenResult = yield call(() => authResponseData);

        const responseData: Response = yield call(() => authApi.signup(data, authResponseBody.access_token));

        const responseBody: SignupResponse = yield call(() => responseData.json());

        if(responseData.ok) {
            const { tokenResults } = responseBody;
            const accessToken = Array.isArray(tokenResults) ? tokenResults[0]?.access_token : tokenResults?.access_token;
            const grantType = Array.isArray(tokenResults) ? tokenResults[0]?.grant_type : tokenResults?.grant_type;
            const expiresIn = Array.isArray(tokenResults) ? tokenResults[0]?.expires_in : tokenResults?.expires_in;
            if(!accessToken) {
                const errorMessage = Array.isArray(tokenResults) ? tokenResults[0]?.error_message : tokenResults?.error_message;
                if (errorMessage) {
                    const model: CustomError = customErrorMapper(errorMessage);
                    yield put(errorActions.clearValidationErrors());
                    yield put(errorActions.addError(model));
                }
                yield put(loadingActions.reset());
                // should throw an exception
                return;
            }

            const primaryToken = Array.isArray(tokenResults) ? tokenResults[1]?.primary_token : tokenResults?.primary_token;
            if (primaryToken) {
                const primaryGrantType = Array.isArray(tokenResults) ? tokenResults[1]?.grant_type : tokenResults?.grant_type;
                const primaryExpiresIn = Array.isArray(tokenResults) ? tokenResults[1]?.expires_in : tokenResults?.expires_in;
                yield put(authActions.setPrimaryToken({name: "primaryToken", tokens: {primary_token: primaryToken, grant_type: primaryGrantType, expires_in: primaryExpiresIn}}));
            }

            yield put(authActions.login({apiName: "api", tokens: {access_token: accessToken, grant_type: grantType, expires_in: expiresIn}}));

            yield put(sharedActions.getProfile());

            yield take(getType(sharedActions.loadUserProfileCompleted));
        }
    } catch(e) {
        yield put(loadingActions.reset());
    } finally {
        yield put(loadingActions.complete());
        const organization: OrganizationModel = yield select(organizationSelector);
        yield put(routerActions.redirect(`/org${organization.id}/setup/customize`));
    }
}

function* validateSignUpModel(action: ActionType<typeof actions.validateSignUpModel>) {
    const model = action.payload;
    const isValid: boolean = yield validateSignUpModelBase(model);
    yield put(actions.validateSignUpModelCompleted(isValid));
}

function* signupInvitedUser({ payload }: ReturnType<typeof actions.signupInvitedUser>) {
    const model: SignupInvitedUserModel = payload;

    yield put(actions.validateInvitedUserSignUpModel({ ...model }));

    const isValid: ActionType<typeof actions.validateSignUpModelCompleted> = yield take(getType(actions.validateSignUpModelCompleted));
    if (!isValid.payload) {
        return;
    }

    yield put(loadingActions.begin());

    try {
        const data = {
            userId: model.userId,
            fullName: model.fullName,
            password: model.password,
            passwordConfirmation: model.passwordConfirmation,
            isTermsApproved: model.isTermsApproved,
        };

        const authResponseData: Response = yield call(() => authApi.initialAuthToken('admin-signup'));

        const authResponseBody: Tina4SignInTokenResult = yield call(() => authResponseData);

        const responseData: Response = yield call(() => authApi.signupInvitedUser(data,  authResponseBody.access_token));
        yield call(handleSignupResponse, responseData);
    } catch(e) {
        console.error(e);
        yield put(loadingActions.reset());
    } finally {
        yield put(loadingActions.complete());
    }
}

function* confirmEmailVerification({ payload }: ReturnType<typeof actions.confirmEmailVerification>) {
    const model = payload;
    yield put(loadingActions.begin());

    const data = mappers.confirmEmailVerificationFromModel(model);

    try {
        const result: ConfirmVerificationResponse = yield call(() => authApi.confirmEmailVerification(data));
        const model = mappers.confirmVerificationResponseToModel(result);
        yield put(sharedActions.setVerifying(model));
    } catch(e) {
        yield put(loadingActions.reset());

        if(e instanceof BadRequestError) {
            const err = customErrorMapper(e.message);
            yield put(errorActions.addError(err));
        }

    } finally {
        yield put(loadingActions.complete());
    }
}

function* resendOTP(action: ReturnType<typeof actions.resendOTP>) {
    yield put(loadingActions.begin());

    try {
        yield call(authApi.resendEmailVerificationCode);
        yield put(sharedActions.setVerifying({ isVerified: false, isExpired: undefined }));
    } catch(e) {
        yield put(loadingActions.reset());

        if(e instanceof BadRequestError) {
            const err = customErrorMapper(e.message);
            yield put(errorActions.addError(err));
        }

    } finally {
        yield put(loadingActions.complete());
    }
}

function* reenterSignupEmail(action: ReturnType<typeof actions.reenterSignupEmail>) {
    yield put(loadingActions.begin());
    const model: ReenterSignupEmailModel = action.payload;
    const data = mappers.reenterSignupEmailFromModel(model);

    try {

        yield call(() => authApi.reenterSignupEmail(data));
        const alert = alertMapper("The login was changed. Check your email and enter verification code.");
        yield put(sharedActions.setReenteringFlag(false));
        yield put(errorActions.addAlert(alert));

    } catch(e) {
        yield put(loadingActions.reset());

        if(e instanceof BadRequestError) {
            const err = customErrorMapper(e.message);
            yield put(errorActions.addError(err));
        }

    } finally {
        yield put(loadingActions.complete());
    }
}

function* validateSignUpModelBase(model: UserSignUpModel) {
    yield put(errorActions.clearValidationErrors());
    yield put(errorActions.clearErrors());

    const validationErrors: ValidationError[] = [];
    const localErrors: CustomError[] = [];
    const emailRegExp = /^\S+@\S+\.\S+$/;

    if(model.isTermsApproved2) return false; // you are a robot.

    if(!model.userName) {
        validationErrors.push({ name: 'FullName', message: 'Full name cannot be empty' });
    }

    if(!model.organizationName) {
        validationErrors.push({ name: 'OrganizationName', message: 'Organization name cannot be empty' });
    }

    if (!model.email) {
        validationErrors.push({ name: 'Email', message: 'Email cannot be empty' });
    } else if (!model.email.match(emailRegExp)) {
        validationErrors.push({ name: 'Email', message: 'The wrong format is used for email' });
    }

    if (!model.password) {
        validationErrors.push({ name: 'Password', message: 'Password cannot be empty' });
    } else if (model.password.length < 4) {
        validationErrors.push({ name: 'Password', message: 'Password cannot be shorter than 4 symbols' });
    }

    if (!model.password2) {
        validationErrors.push({ name: 'PasswordConfirmation', message: 'Repeat password is required' });
    } else if(model.password !== model.password2) {
        validationErrors.push({ name: 'PasswordConfirmation', message: 'Password doesn\'t match' });
    }

    if(!model.isTermsApproved) {
        const err = customErrorMapper("Please check the agreement if you want to proceed");
        localErrors.push(err);
    }

    if (validationErrors.length || localErrors.length) {

        if(validationErrors.length)
            yield put(errorActions.setValidationErrors(validationErrors));

        if(localErrors.length)
            yield put(errorActions.setErrors(localErrors));

        return false;
    }

    return true;
}

function* validateInvitedUserSignUpModel(action: ActionType<typeof actions.validateInvitedUserSignUpModel>) {
    const model = action.payload;
    const isValid: boolean = yield validateInvitedUserSignUpModelBase(model);
    yield put(actions.validateSignUpModelCompleted(isValid));
}

function* validateInvitedUserSignUpModelBase(model: SignupInvitedUserModel) {
    yield put(errorActions.clearValidationErrors());
    yield put(errorActions.clearErrors());

    const validationErrors: ValidationError[] = [];
    const localErrors: CustomError[] = [];

    if(model.isTermsApproved2) return false; // you are a robot.

    if(!model.fullName) {
        validationErrors.push({ name: 'FullName', message: 'Full name cannot be empty' });
    }

    if (!model.password) {
        validationErrors.push({ name: 'Password', message: 'Password cannot be empty' });
    } else if (model.password.length < 4) {
        validationErrors.push({ name: 'Password', message: 'Password cannot be shorter than 4 symbols' });
    }

    if (!model.passwordConfirmation) {
        validationErrors.push({ name: 'PasswordConfirmation', message: 'Repeat password is required' });
    } else if(model.password !== model.passwordConfirmation) {
        validationErrors.push({ name: 'PasswordConfirmation', message: 'Password doesn\'t match' });
    }

    if(!model.isTermsApproved) {
        const err = customErrorMapper("Please check the agreement if you want to proceed");
        localErrors.push(err);
    }

    if (validationErrors.length || localErrors.length) {

        if(validationErrors.length)
            yield put(errorActions.setValidationErrors(validationErrors));

        if(localErrors.length)
            yield put(errorActions.setErrors(localErrors));

        return false;
    }

    return true;
}

function* handleSignupResponse(response: Response) {
    yield put(errorActions.clearValidationErrors());
    yield put(errorActions.clearErrors());
    yield put(loadingActions.begin());
    const responseBody: SignupResponse = yield call(() => response.json());
    if (response.ok) {
        const  tokenResults = responseBody as unknown as AccessTokenResult|AccessTokenResult[];
        const accessToken = Array.isArray(tokenResults) ? tokenResults[0]?.access_token : tokenResults?.access_token;
        const grantType = Array.isArray(tokenResults) ? tokenResults[0]?.grant_type : tokenResults?.grant_type;
        const expiresIn = Array.isArray(tokenResults) ? tokenResults[0]?.expires_in : tokenResults?.expires_in;
        if(!accessToken) {
            // bad request error
            const errorMessage = Array.isArray(tokenResults) ? tokenResults[0]?.error_message : tokenResults?.error_message;
            if (errorMessage) {
                const model: CustomError = customErrorMapper(errorMessage);
                yield put(errorActions.clearValidationErrors());
                yield put(errorActions.addError(model));
            }
            yield put(loadingActions.reset());
            return;
        }
        const primaryToken = Array.isArray(tokenResults) ? tokenResults[1]?.primary_token : tokenResults?.primary_token;
        if (primaryToken) {
            const primaryGrantType = Array.isArray(tokenResults) ? tokenResults[1]?.grant_type : tokenResults?.grant_type;
            const primaryExpiresIn = Array.isArray(tokenResults) ? tokenResults[1]?.expires_in : tokenResults?.expires_in;
            yield put(authActions.setPrimaryToken({name: "primaryToken", tokens: {primary_token: primaryToken, grant_type: primaryGrantType, expires_in: primaryExpiresIn}}));
        }

        // yield put(routerActions.redirect(`/signup?signupStatus=verify`));

        yield put(authActions.login({apiName: "api", tokens: {access_token: accessToken, grant_type: grantType, expires_in: expiresIn}}));

        yield put(sharedActions.getProfile());

        yield take(getType(sharedActions.loadUserProfileCompleted));

    } else {
        yield call(handleErrorRequest, responseBody.errors);
        yield put(loadingActions.reset());
    }
    // handle error response !!!
}
