import { combineReducers } from 'redux';
import sharedReducer from './shared/sharedReducer';
import routerReducer from './shared/router/routerReducer';
import accountReducer from './AccountPage/accountReducer';
import setupReducer from './SetupPage/setupReducer';
import lookupReducer from './lookups/lookupReducer';
import authReducer from './auth/redux/reducers';
import loadingReducer from "./shared/loading/loadingStateReducer";
import errorsReducer from "./shared/errors/errorsReducer";
import { feedbackSubmitReducer, feedbackReducer } from './Feedback/feedbackReducer';
import {reviewsReducer} from "./ReviewsPage/reviewsReducer";
import reportingReducer from "./ReportingPage/reportingReducer";
import settingsReducer from './SettingsPage/settingsReducer';
import { getType } from "typesafe-actions";
import * as sharedActions from "./shared/sharedActions";

// set state in <>
const appReducer = combineReducers({
    router: routerReducer,
    globals: sharedReducer,
    account: accountReducer,
    setup: setupReducer,
    lookups: lookupReducer,
    loading: loadingReducer,
    errors: errorsReducer,
    feedbackSubmission: feedbackSubmitReducer,
    feedback: feedbackReducer,
    reviews: reviewsReducer,
    summary: reportingReducer,
    settings: settingsReducer,

    // need to move
    auth: authReducer,
})

const rootReducer = (state: any, action: any) => {
    if (action.type === getType(sharedActions.clearStore)) {
        state = undefined;
    }

    return appReducer(state, action);
};

export default rootReducer;
