import React, {useEffect, useRef, useState} from "react";
import {Box, Button, IconButton, makeStyles, Typography} from "@material-ui/core";
import Icon from "../../shared/components/Icon";
import UploadIcon from "../../svg/upload.svg";
import ClearIcon from "@material-ui/icons/Clear";
import {appSettings} from "../../config/appSettings";

const staticDataUrl = appSettings.app.staticDataUrl;

interface ImageUploadComponentProps {
    currentImage: string | undefined,
    uploadFileAction: (file: File) => boolean,
    inputLabel: string,
}

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    input: {
        display: 'none',
    },
    btn: {
        display: "block",
    },
    upload: {
        textAlign: "center",
    },
}));
function ImageUploadComponent(props: ImageUploadComponentProps) {
    const classes = useStyles();
    const [file, setFile] = useState<File | null>();

    const [dragging, setDragging] = useState(false);
    let dragCounter = 0;

    const dropRef = useRef<HTMLDivElement>(null);
    function handleDrag(e: any) {
        e.preventDefault()
        e.stopPropagation()
    }

    function handleDragIn(e: any) {
        e.preventDefault()
        e.stopPropagation()
        dragCounter++;
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            setDragging(true);
        }
    }

    function handleDragOut(e: any) {
        e.preventDefault()
        e.stopPropagation()
        dragCounter--;
        if (dragCounter === 0) {
            setDragging(false);
        }
    }

    function handleDragStart(e: any) {
        e.preventDefault();
        e.stopPropagation();
        e.dataTransfer.clearData();
    }

    function handleDrop(e: any) {
        e.preventDefault()
        e.stopPropagation()
        setDragging(false);

        if (e.dataTransfer.files.length) {
            //   props.handleDrop(e.dataTransfer.files);
            setFile(e.dataTransfer.files[0]);
            //   e.dataTransfer.clearData();
            dragCounter = 0;
        }
    }

    useEffect(() => {
        let div = dropRef.current;
        div!.addEventListener('dragenter', handleDragIn);
        div!.addEventListener('dragleave', handleDragOut);
        div!.addEventListener('dragover', handleDrag);
        div!.addEventListener('dragstart', handleDragStart);
        div!.addEventListener('drop', handleDrop);

        return () => {
            let div = dropRef.current;
            if(div) {
                div!.removeEventListener('dragenter', handleDragIn);
                div!.removeEventListener('dragleave', handleDragOut);
                div!.removeEventListener('dragover', handleDrag);
                div!.removeEventListener('dragstart', handleDragStart);
                div!.removeEventListener('drop', handleDrop);
            }
        };
    },[])

    const chooseFileHandler = (e: React.ChangeEvent<{ files?: any; }>) => {
        setFile(e.target.files[0]);
    }

    const clearUploadedFileHandler = () => {
        setFile(null);
    };

    const getElementIdString = () => {
        const strArr = props.inputLabel.split(' ');
        const stringArray = strArr.map(val => {
            return val.toLowerCase();
        });
        return stringArray.join('-');
    };

    const uploadFileHandler = () => {
        if(file) {
            if (props.uploadFileAction(file) ) {
                setFile(null);
            }
        }
    };

    return (
        <>
            <Typography style={{ marginTop: "14px", marginBottom: "14px", fontWeight: 700 }} >{props.inputLabel}</Typography>
            <Box display="flex" width={"100%"}>
                <Box className="current-agency-logo" flexGrow="1" padding={"0.5rem"}>
                    { props.currentImage &&
                        <img src={staticDataUrl + props.currentImage} alt="Image" />
                    }
                </Box>

                <Box width={"50%"}>
                    <div
                        className="dnd-container"
                        ref={dropRef}
                    >
                        {dragging &&
                            <div
                                className="dnd"
                            ></div>
                        }
                        <Box>
                            <Icon
                                src={UploadIcon}
                                className="margin-right"
                            />
                            <Box className={classes.upload}>
                                <Typography><b>Upload a new {props.inputLabel}</b></Typography>
                                <Typography style={{ fontSize: "12px", }}>
                                    Drag & Drop
                                </Typography>
                                <input
                                    accept="image/jpeg, image/png, image/x-icon"
                                    className={classes.input}
                                    id={`${getElementIdString()}-contained-button-file`}
                                    name={`${getElementIdString()}-file`}
                                    multiple
                                    type="file"
                                    onChange={e => chooseFileHandler(e)}
                                />
                                {
                                    file ?
                                        <Box display="flex" alignItems="center">
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                component="span"
                                                size="small"
                                                onClick={uploadFileHandler}
                                                style={{ marginRight: "14px" }}
                                            >
                                                Upload File
                                            </Button>
                                            <Typography>{file.name}</Typography>
                                            <IconButton style={{ color: "red" }} aria-label="upload picture" component="span" onClick={clearUploadedFileHandler}>
                                                <ClearIcon fontSize="small" />
                                            </IconButton>
                                        </Box>
                                        :
                                        <label className={classes.btn} htmlFor={`${getElementIdString()}-contained-button-file`}>
                                            <Box display="flex">
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    component="span"
                                                    size="small"
                                                    className="margin-right"
                                                    style={{ marginRight: "14px" }}
                                                >
                                                    Choose File
                                                </Button>
                                                <Typography>No File Chosen</Typography>
                                            </Box>
                                        </label>
                                }

                            </Box>
                        </Box>
                    </div>
                </Box>
            </Box>
        </>
    )
}

export default ImageUploadComponent;
